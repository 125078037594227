<template>
	<div class='wrapper'>
		<!-- 导航栏 header -->
		<header class='header header-black'>
			<div class='header-content clearfix'>
				<h1 class='header-logo'>
					<a href='/' class='header-logo-link'>一美助手</a>
				</h1>
				<div class='header-nav'>
					<router-link to='/'>首页</router-link>
					<router-link to='/project-baike'>项目百科</router-link>
					<router-link to='/about'>关于我们</router-link>
					<a href='https://www.zhipin.com/gongsir/7c04c90e9346fcd833V52dq0GQ~~.html?ka=company-jobs'
						target='view_window'>加入我们</a>
					<router-link to="/settled">机构入驻</router-link>
					<a href='javascript:void(0)' onclick='openJesongChatByGroup(33097,40372);return false;'>在线客服</a>
				</div>
			</div>
		</header>

		<!-- 内容 -->
		<div class='baike'>
			<div class='con-1180'>
				<div class='section-title'>全部项目</div>
				<div class='content-box' style="background: #fff;">
					<div class='classify'>
						<div class='classify-item' v-for='(item,index) in classifyList' :key='index'
							@click='fnSection(item)'>
							<img class='classify-icon' :src='item.icon'>
							<span class='classify-title'>{{item.classify}}</span>
						</div>
						<div class='classify-more'>
							<img class='classify-icon' src='../assets/icons/project-bk-icon-qidai.png'>
							<span class='classify-title'>更多项目敬请期待 ~</span>
						</div>
					</div>
				</div>
			</div>

			<!-- 皮肤美容 -->
			<div class='con-1180 item-block' id="product01">
				<div class="tab">
					<div class='tab-title'>皮肤美容</div>
					<div class="tab-box">
						<div class='tab-nav'>
							<div class='cap' :class="item.secondID==pf.tabCur2?'nav-active':''"
								v-for="(item,index) in pifu.pifu" :key="index" @click="fnPFTabCur(item)">
								{{item.secondItitle}}
							</div>
						</div>

						<div class='content-box' v-show="item.secondID==pf.tabCur2" v-for="(item,index) in pifu.pifu"
							:key="index">
							<div class="product-tag" :class="item2.thirdID==pf.tabCur3?'product-tag-a':''"
								v-for="(item2,index2) in item.thirdList" :key="index2" @click="fnPFTabCur2(item2)">
								{{item2.thirdTitle}}
							</div>

							<div class="" v-show="item2.thirdID==pf.tabCur3" v-for="(item2) in item.thirdList"
								:key="item2.thirdID">
								<div class="product-richtext">{{item2.symptom}}</div>
								<div class="product-richtext">{{item2.desc}}</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<!-- 眼部整形 -->
			<div class='con-1180 item-block' id="product02">
				<div class="tab">
					<div class='tab-title'>眼部整形</div>
					<div class="tab-box">
						<div class='tab-nav'>
							<div class='cap' :class="item.secondID==yj.tabCur2?'nav-active':''"
								v-for="(item,index) in yanjing.yanjing" :key="index" @click="fnYJTabCur(item)">
								{{item.secondItitle}}
							</div>
						</div>
					</div>

					<div class='content-box' v-show="item.secondID==yj.tabCur2" v-for="(item,index) in yanjing.yanjing"
						:key="index">
						<div class="product-tag" :class="item2.thirdID==yj.tabCur3?'product-tag-a':''"
							v-for="(item2,index2) in item.thirdList" :key="index2" @click="fnYJTabCur2(item2)">
							{{item2.thirdTitle}}
						</div>

						<div class="" v-show="item2.thirdID==yj.tabCur3" v-for="item2 in item.thirdList"
							:key="item2.thirdID">
							<div class="product-richtext">{{item2.symptom}}</div>
							<div class="product-richtext">{{item2.desc}}</div>
						</div>
					</div>
				</div>
			</div>

			<!-- 鼻部整形 -->
			<div class='con-1180 item-block' id="product03">
				<div class="tab">
					<div class='tab-title'>鼻部整形</div>
					<div class="tab-box">
						<div class='tab-nav'>
							<div class='cap' :class="item.secondID==bz.tabCur2?'nav-active':''"
								v-for="(item,index) in bizi.bizi" :key="index" @click="fnBZTabCur(item)">
								{{item.secondItitle}}
							</div>
						</div>
					</div>

					<div class='content-box' v-show="item.secondID==bz.tabCur2" v-for="(item,index) in bizi.bizi"
						:key="index">
						<div class="product-tag" :class="item2.thirdID==bz.tabCur3?'product-tag-a':''"
							v-for="(item2,index2) in item.thirdList" :key="index2" @click="fnBZTabCur2(item2)">
							{{item2.thirdTitle}}
						</div>

						<div class="" v-show="item2.thirdID==bz.tabCur3" v-for="item2 in item.thirdList"
							:key="item2.thirdID">
							<div class="product-richtext">{{item2.symptom}}</div>
							<div class="product-richtext">{{item2.desc}}</div>
						</div>
					</div>
				</div>
			</div>

			<!-- 面部轮廓 -->
			<div class='con-1180 item-block' id="product04">
				<div class="tab">
					<div class='tab-title'>面部轮廓</div>
					<div class="tab-box">
						<div class='tab-nav'>
							<div class='cap' :class="item.secondID==mb.tabCur2?'nav-active':''"
								v-for="(item,index) in mianbu.mianbu" :key="index" @click="fnMBTabCur(item)">
								{{item.secondItitle}}
							</div>
						</div>
					</div>

					<div class='content-box' v-show="item.secondID==mb.tabCur2" v-for="(item,index) in mianbu.mianbu"
						:key="index">
						<div class="product-tag" :class="item2.thirdID==mb.tabCur3?'product-tag-a':''"
							v-for="(item2,index2) in item.thirdList" :key="index2" @click="fnMBTabCur2(item2)">
							{{item2.thirdTitle}}
						</div>

						<div class="" v-show="item2.thirdID==mb.tabCur3" v-for="item2 in item.thirdList"
							:key="item2.thirdID">
							<div class="product-richtext">{{item2.symptom}}</div>
							<div class="product-richtext">{{item2.desc}}</div>
						</div>
					</div>
				</div>
			</div>

			<!-- 口唇酒窝 -->
			<div class='con-1180 item-block' id="product05">
				<div class="tab">
					<div class='tab-title'>口唇酒窝</div>
					<div class="tab-box">
						<div class='tab-nav'>
							<div class='cap' :class="item.secondID==kc.tabCur2?'nav-active':''"
								v-for="(item,index) in kouchun.kouchun" :key="index" @click="fnKCTabCur(item)">
								{{item.secondItitle}}
							</div>
						</div>
					</div>

					<div class='content-box' v-show="item.secondID==kc.tabCur2" v-for="(item,index) in kouchun.kouchun"
						:key="index">
						<div class="product-tag" :class="item2.thirdID==kc.tabCur3?'product-tag-a':''"
							v-for="(item2,index2) in item.thirdList" :key="index2" @click="fnKCTabCur2(item2)">
							{{item2.thirdTitle}}
						</div>

						<div class="" v-show="item2.thirdID==kc.tabCur3" v-for="item2 in item.thirdList"
							:key="item2.thirdID">
							<div class="product-richtext">{{item2.symptom}}</div>
							<div class="product-richtext">{{item2.desc}}</div>
						</div>
					</div>
				</div>
			</div>

			<!-- 半永久妆 -->
			<div class='con-1180 item-block' id="product06">
				<div class="tab">
					<div class='tab-title'>半永久妆</div>
					<div class="tab-box">
						<div class='tab-nav'>
							<div class='cap' :class="item.secondID==byj.tabCur2?'nav-active':''"
								v-for="(item,index) in banyongjiu.banyongjiu" :key="index" @click="fnBYJTabCur(item)">
								{{item.secondItitle}}
							</div>
						</div>
					</div>

					<div class='content-box' v-show="item.secondID==byj.tabCur2"
						v-for="(item,index) in banyongjiu.banyongjiu" :key="index">
						<div class="product-tag" :class="item2.thirdID==byj.tabCur3?'product-tag-a':''"
							v-for="(item2,index2) in item.thirdList" :key="index2" @click="fnBYJTabCur2(item2)">
							{{item2.thirdTitle}}
						</div>

						<div class="" v-show="item2.thirdID==byj.tabCur3" v-for="item2 in item.thirdList"
							:key="item2.thirdID">
							<div class="product-richtext">{{item2.symptom}}</div>
							<div class="product-richtext">{{item2.desc}}</div>
						</div>
					</div>
				</div>
			</div>

			<!-- 抗衰提升 -->
			<div class='con-1180 item-block' id="product07">
				<div class="tab">
					<div class='tab-title'>抗衰提升</div>
					<div class="tab-box">
						<div class='tab-nav'>
							<div class='cap' :class="item.secondID==ks.tabCur2?'nav-active':''"
								v-for="(item,index) in kangshuai.kangshuai" :key="index" @click="fnKSTabCur(item)">
								{{item.secondItitle}}
							</div>
						</div>
					</div>

					<div class='content-box' v-show="item.secondID==ks.tabCur2"
						v-for="(item,index) in kangshuai.kangshuai" :key="index">
						<div class="product-tag" :class="item2.thirdID==ks.tabCur3?'product-tag-a':''"
							v-for="(item2,index2) in item.thirdList" :key="index2" @click="fnKSTabCur2(item2)">
							{{item2.thirdTitle}}
						</div>

						<div class="" v-show="item2.thirdID==ks.tabCur3" v-for="item2 in item.thirdList"
							:key="item2.thirdID">
							<div class="product-richtext">{{item2.symptom}}</div>
							<div class="product-richtext">{{item2.desc}}</div>
						</div>
					</div>
				</div>
			</div>

			<!-- 塑身减肥 -->
			<div class='con-1180 item-block' id="product08">
				<div class="tab">
					<div class='tab-title'>塑身减肥</div>
					<div class="tab-box">
						<div class='tab-nav'>
							<div class='cap' :class="item.secondID==jf.tabCur2?'nav-active':''"
								v-for="(item,index) in jianfei.jianfei" :key="index" @click="fnJFTabCur(item)">
								{{item.secondItitle}}
							</div>
						</div>
					</div>

					<div class='content-box' v-show="item.secondID==jf.tabCur2" v-for="(item,index) in jianfei.jianfei"
						:key="index">
						<div class="product-tag" :class="item2.thirdID==jf.tabCur3?'product-tag-a':''"
							v-for="(item2,index2) in item.thirdList" :key="index2" @click="fnJFTabCur2(item2)">
							{{item2.thirdTitle}}
						</div>

						<div class="" v-show="item2.thirdID==jf.tabCur3" v-for="item2 in item.thirdList"
							:key="item2.thirdID">
							<div class="product-richtext">{{item2.symptom}}</div>
							<div class="product-richtext">{{item2.desc}}</div>
						</div>
					</div>
				</div>
			</div>

			<!-- 胸部整形 -->
			<div class='con-1180 item-block' id="product09">
				<div class="tab">
					<div class='tab-title'>胸部整形</div>
					<div class="tab-box">
						<div class='tab-nav'>
							<div class='cap' :class="item.secondID==xb.tabCur2?'nav-active':''"
								v-for="(item,index) in xiongbu.xiongbu" :key="index" @click="fnXBTabCur(item)">
								{{item.secondItitle}}
							</div>
						</div>
					</div>

					<div class='content-box' v-show="item.secondID==xb.tabCur2" v-for="(item,index) in xiongbu.xiongbu"
						:key="index">
						<div class="product-tag" :class="item2.thirdID==xb.tabCur3?'product-tag-a':''"
							v-for="(item2,index2) in item.thirdList" :key="index2" @click="fnXBTabCur2(item2)">
							{{item2.thirdTitle}}
						</div>

						<div class="" v-show="item2.thirdID==xb.tabCur3" v-for="item2 in item.thirdList"
							:key="item2.thirdID">
							<div class="product-richtext">{{item2.symptom}}</div>
							<div class="product-richtext">{{item2.desc}}</div>
						</div>
					</div>
				</div>
			</div>

			<!-- 口腔 -->
			<div class='con-1180 item-block' id="product10">
				<div class="tab">
					<div class='tab-title'>口腔</div>
					<div class="tab-box">
						<div class='tab-nav'>
							<div class='cap' :class="item.secondID==kq.tabCur2?'nav-active':''"
								v-for="(item,index) in kouqiang.kouqiang" :key="index" @click="fnKQTabCur(item)">
								{{item.secondItitle}}
							</div>
						</div>
					</div>

					<div class='content-box' v-show="item.secondID==kq.tabCur2"
						v-for="(item,index) in kouqiang.kouqiang" :key="index">
						<div class="product-tag" :class="item2.thirdID==kq.tabCur3?'product-tag-a':''"
							v-for="(item2,index2) in item.thirdList" :key="index2" @click="fnKQTabCur2(item2)">
							{{item2.thirdTitle}}
						</div>

						<div class="" v-show="item2.thirdID==kq.tabCur3" v-for="item2 in item.thirdList"
							:key="item2.thirdID">
							<div class="product-richtext">{{item2.symptom}}</div>
							<div class="product-richtext">{{item2.desc}}</div>
						</div>
					</div>
				</div>
			</div>

			<!-- 光电微整形 -->
			<div class='con-1180 item-block' id="product11">
				<div class="tab">
					<div class='tab-title'>光电微整形</div>
					<div class="tab-box">
						<div class='tab-nav'>
							<div class='cap' :class="item.secondID==dg.tabCur2?'nav-active':''"
								v-for="(item,index) in dianguang.dianguang" :key="index" @click="fnDGTabCur(item)">
								{{item.secondItitle}}
							</div>
						</div>
					</div>

					<div class='content-box' v-show="item.secondID==dg.tabCur2"
						v-for="(item,index) in dianguang.dianguang" :key="index">
						<div class="product-tag" :class="item2.thirdID==dg.tabCur3?'product-tag-a':''"
							v-for="(item2,index2) in item.thirdList" :key="index2" @click="fnDGTabCur2(item2)">
							{{item2.thirdTitle}}
						</div>

						<div class="" v-show="item2.thirdID==dg.tabCur3" v-for="item2 in item.thirdList"
							:key="item2.thirdID">
							<div class="product-richtext">{{item2.symptom}}</div>
							<div class="product-richtext">{{item2.desc}}</div>
						</div>
					</div>
				</div>
			</div>

			<!-- 注射微整形 -->
			<div class='con-1180 item-block' id="product12">
				<div class="tab">
					<div class='tab-title'>注射微整形</div>
					<div class="tab-box">
						<div class='tab-nav'>
							<div class='cap' :class="item.secondID==zs.tabCur2?'nav-active':''"
								v-for="(item,index) in zhushe.zhushe" :key="index" @click="fnZSTabCur(item)">
								{{item.secondItitle}}
							</div>
						</div>
					</div>

					<div class='content-box' v-show="item.secondID==zs.tabCur2" v-for="(item,index) in zhushe.zhushe"
						:key="index">
						<div class="product-tag" :class="item2.thirdID==zs.tabCur3?'product-tag-a':''"
							v-for="(item2,index2) in item.thirdList" :key="index2" @click="fnZSTabCur2(item2)">
							{{item2.thirdTitle}}
						</div>

						<div class="" v-show="item2.thirdID==zs.tabCur3" v-for="item2 in item.thirdList"
							:key="item2.thirdID">
							<div class="product-richtext">{{item2.symptom}}</div>
							<div class="product-richtext">{{item2.desc}}</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<!-- 返回顶部按钮 -->
		<div class="back" v-if="btnFlag">
			<img class="back-icon" src="../assets/icons/up.png" @click="backTop">
		</div>

		<!-- 底部footer  -->
		<div class='footer'>
			<div class='content-filing'>
				<p class='content-title'>安徽一美科技有限公司</p>
				<p>皖网文（2019）6047-305号 <span @click='handleICP'>皖ICP备15026816号</span></p>
			</div>
		</div>

	</div>
</template>

<script>
	import first from '../assets/json/first.json'
	import data from '../assets/json/data.json'
	export default {
		name: 'Baike',
		components: {},
		props: {},
		data() {
			return {
				btnFlag: false, // 返回顶部按钮是否显示
				classifyList: [],
				pifu: [],
				// 皮肤
				pf: {
					tabCur2: 'p01',
					tabCur3: 'p01'
				},
				yanjing: [],
				// 眼睛
				yj: {
					tabCur2: 'y01',
					tabCur3: 'y01'
				},
				// 鼻子
				bizi: [],
				bz: {
					tabCur2: 'b01',
					tabCur3: 'b01'
				},
				// 面部
				mianbu: [],
				mb: {
					tabCur2: 'm01',
					tabCur3: 'm01'
				},
				// 口唇
				kouchun: [],
				kc: {
					tabCur2: 'k01',
					tabCur3: 'k01'
				},
				// 半永久
				banyongjiu: [],
				byj: {
					tabCur2: 'byj01',
					tabCur3: 'byj01'
				},
				// 抗衰
				kangshuai: [],
				ks: {
					tabCur2: 'ks01',
					tabCur3: 'ks01'
				},
				// 塑身减肥
				jianfei: [],
				jf: {
					tabCur2: 'j01',
					tabCur3: 'j01'
				},
				// 胸部
				xiongbu: [],
				xb: {
					tabCur2: 'x01',
					tabCur3: 'x01'
				},
				// 口腔
				kouqiang: [],
				kq: {
					tabCur2: 'kq01',
					tabCur3: 'kq01'
				},
				// 电光
				dianguang: [],
				dg: {
					tabCur2: 'd01',
					tabCur3: 'd01'
				},
				// 注射
				zhushe: [],
				zs: {
					tabCur2: 'z01',
					tabCur3: 'z01'
				}
			}
		},
		computed: {},
		watch: {},
		created() {},
		mounted() {
			window.addEventListener('scroll', this.scrollToTop)
			// 大分类
			this.classifyList = first.classifyList
			// 皮肤数据
			this.pifu = data.firstWork
			// 眼睛数据
			this.yanjing = data.secondWork
			// 鼻子数据
			this.bizi = data.thirdWork
			// 面部
			this.mianbu = data.fourthWork
			// 口唇
			this.kouchun = data.fifthWork
			// 半永久
			this.banyongjiu = data.sixthWork
			// 抗衰
			this.kangshuai = data.seventhWork
			// 塑身减肥
			this.jianfei = data.eighthWork
			// 胸部
			this.xiongbu = data.ninthWork
			// 口腔
			this.kouqiang = data.tenthWork
			// 电光
			this.dianguang = data.eleventhWork
			// 注射
			this.zhushe = data.twelfthWork
		},
		destroyed() {
			window.removeEventListener('scroll', this.scrollToTop)
		},
		methods: {
			// 页面内部跳转(第一重循环)
			fnSection(item) {
				document.getElementById(item.href).style = 'margin-top:148px; transition: 1s;'
				document.getElementById(item.href).scrollIntoView({
					behavior: 'smooth',
					block: 'start'
				})
			},
			// 返回顶部
			backTop() {
				const that = this
				const timer = setInterval(() => {
					const ispeed = Math.floor(-that.scrollTop / 5)
					document.documentElement.scrollTop = document.body.scrollTop = that.scrollTop + ispeed
					if (that.scrollTop === 0) {
						clearInterval(timer)
					}
				}, 16)
			},
			// 为了计算距离顶部的高度，当高度大于60显示回顶部图标，小于60则隐藏
			scrollToTop() {
				const that = this
				const scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
				that.scrollTop = scrollTop
				if (that.scrollTop > 60) {
					that.btnFlag = true
				} else {
					that.btnFlag = false
				}
			},

			// 皮肤
			fnPFTabCur(item) {
				this.pf.tabCur3 = 'p01'
				this.pf.tabCur2 = item.secondID
			},

			fnPFTabCur2(item2) {
				this.pf.tabCur3 = item2.thirdID
			},
			// 眼睛
			fnYJTabCur(item) {
				this.yj.tabCur3 = 'y01'
				this.yj.tabCur2 = item.secondID
			},
			fnYJTabCur2(item2) {
				this.yj.tabCur3 = item2.thirdID
			},
			// 鼻子
			fnBZTabCur(item) {
				this.bz.tabCur3 = 'b01'
				this.bz.tabCur2 = item.secondID
			},
			fnBZTabCur2(item2) {
				this.bz.tabCur3 = item2.thirdID
			},
			// 面部
			fnMBTabCur(item) {
				this.mb.tabCur3 = 'm01'
				this.mb.tabCur2 = item.secondID
			},
			fnMBTabCur2(item2) {
				this.mb.tabCur3 = item2.thirdID
			},
			// 口唇
			fnKCTabCur(item) {
				this.kc.tabCur3 = 'k01'
				this.kc.tabCur2 = item.secondID
			},
			fnKCTabCur2(item2) {
				this.kc.tabCur3 = item2.thirdID
			},
			// 半永久
			fnBYJTabCur(item) {
				this.byj.tabCur3 = 'byj01'
				this.byj.tabCur2 = item.secondID
			},
			fnBYJTabCur2(item2) {
				this.byj.tabCur3 = item2.thirdID
			},
			// 抗衰
			fnKSTabCur(item) {
				this.ks.tabCur3 = 'ks01'
				this.ks.tabCur2 = item.secondID
			},
			fnKSTabCur2(item2) {
				this.ks.tabCur3 = item2.thirdID
			},
			// 塑身减肥
			fnJFTabCur(item) {
				this.jf.tabCur3 = 'j01'
				this.jf.tabCur2 = item.secondID
			},
			fnJFTabCur2(item2) {
				this.jf.tabCur3 = item2.thirdID
			},
			// 胸部
			fnXBTabCur(item) {
				this.xb.tabCur3 = 'x01'
				this.xb.tabCur2 = item.secondID
			},
			fnXBTabCur2(item2) {
				this.xb.tabCur3 = item2.thirdID
			},
			// 口腔
			fnKQTabCur(item) {
				this.kq.tabCur3 = 'kq01'
				this.kq.tabCur2 = item.secondID
			},
			fnKQTabCur2(item2) {
				this.kq.tabCur3 = item2.thirdID
			},
			// 电光
			fnDGTabCur(item) {
				this.dg.tabCur3 = 'd01'
				this.dg.tabCur2 = item.secondID
			},
			fnDGTabCur2(item2) {
				this.dg.tabCur3 = item2.thirdID
			},
			// 注射
			fnZSTabCur(item) {
				this.zs.tabCur3 = 'z01'
				this.zs.tabCur2 = item.secondID
			},
			fnZSTabCur2(item2) {
				this.zs.tabCur3 = item2.thirdID
			},
			// 跳转备案
			handleICP() {
				window.open('https://beian.miit.gov.cn/', '_blank')
			}
		}
	}
</script>

<style lang='less' scoped>
	@import './baike.less';
</style>
