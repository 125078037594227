import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

// 加载 reset.css
import './styles/reset.css'

// 加载 swiper
import 'swiper/css/swiper.min.css'

// 使用 animate.css
import animated from 'animate.css'

import axios from 'axios'

// 加载 element 组件库
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import 'element-ui/lib/theme-chalk/display.css'

Vue.use(animated)
Vue.use(ElementUI)
Vue.config.productionTip = false
Vue.prototype.$axios = axios 

// Vue.prototype.resetSetItem = function(key, newVal) {
// 	if (key === 'navFlagStorage') {
// 		// 创建一个StorageEvent事件
// 		var newStorageEvent = document.createEvent('StorageEvent')
// 		const storage = {
// 			setItem: function(k, val) {
// 				sessionStorage.setItem(k, val)
// 				// 初始化创建的事件
// 				newStorageEvent.initStorageEvent('setItem', false, false, k, null, val, null, null)
// 				// 派发对象
// 				window.dispatchEvent(newStorageEvent)
// 			}
// 		}
// 		return storage.setItem(key, newVal)
// 	}
// }

new Vue({
	router,
	store,
	render: h => h(App)
}).$mount('#app')
