<template>
	<div class="about">
		<header class="header header-black">
			<div class="header-content clearfix">
				<h1 class="header-logo">
					<a href="/" class="header-logo-link">一美助手</a>
				</h1>
				<div class="header-nav">
					<router-link to="/">首页</router-link>
					<router-link to="/project-baike">项目百科</router-link>
					<router-link to="/about">关于我们</router-link>
					<a href="https://www.zhipin.com/gongsir/7c04c90e9346fcd833V52dq0GQ~~.html?ka=company-jobs"
						target="view_window">加入我们</a>
					<router-link to="/settled">机构入驻</router-link>
					<a href="javascript:void(0)" onclick="openJesongChatByGroup(33097,40372);return false;">在线客服</a>
				</div>
			</div>
		</header>
		<div class="about-us">
			<section class="section section-desc">
				<div class="section-title">关于一美助手</div>
				<div class="section-content clearfix">
					<div class="contact-text l_about_intro_descriptions">
						<p>一美助手是基于线上医美资源共享的产品目的，建立链接百万医美经纪人的资源型平台。成立于2019年初，一美助手以“赋能百万医美经纪人”为使命，愿景全力打造“国际一流的医美经纪平台”。医美工具平台，旗产品功能为：是一种利用互联网技术，‌通过收集、‌整理和分享各种案例，‌为不同领域的学习者、‌研究人员和实践者提供参考和借鉴的平台。‌这些平台通常包含各种类型的案例，‌如企业管理、‌公共管理、‌教育、‌医疗等，‌旨在通过具体案例的分析和讨论，‌促进知识的学习、‌技能的提升和问题的解决。平台内部，所有的内容均来自创作者自主上传，平台只做展示作用，创作者上传前，必须了解用户发布作品隐私协议政策，并且提示上传的素材来源原创或转载。
						</p>
						<p>在颜值经济带来的新兴市场浪潮下，乘风破浪的医美行业，万亿市场正逐渐被打开。一美助手基于行业痛点、基本诉求给予美业一线的医美经纪人以高规范高质量的行业解答。让每一位来到一美助手的用户都有所获。
						</p>
						<p> 加入一美助手，驭着行业浪潮，向成功的彼岸进发！</p>
					</div>
				</div>
			</section>
			<section class="section section-culture">
				<div class="section-title l_about_culture_title">企业文化</div>
				<div class="culture-slogan">
					<div class="slogan-item">
						<img src="../assets/images/culture-1.png">
						<p>专业</p>
					</div>
					<div class="slogan-item">
						<img src="../assets/images/culture-2.png">
						<p>高效</p>
					</div>
					<div class="slogan-item">
						<img src="../assets/images/culture-3.png">
						<p>专注</p>
					</div>
					<div class="slogan-item">
						<img src="../assets/images/culture-4.png">
						<p>严谨</p>
					</div>
					<div class="slogan-item">
						<img src="../assets/images/culture-5.png">
						<p>诚信</p>
					</div>
					<div class="slogan-item">
						<img src="../assets/images/culture-6.png">
						<p>创新</p>
					</div>
				</div>
				<div class="swiper-container">
					<div class="swiper-wrapper">
						<div class="swiper-slide">
							<img src="../assets/images/swiper-1.png">
						</div>
						<div class="swiper-slide">
							<img src="../assets/images/swiper-2.png">
						</div>
						<div class="swiper-slide">
							<img src="../assets/images/swiper-3.png">
						</div>
						<div class="swiper-slide">
							<img src="../assets/images/swiper-4.png">
						</div>
						<div class="swiper-slide">
							<img src="../assets/images/swiper-5.png">
						</div>
						<div class="swiper-slide">
							<img src="../assets/images/swiper-6.png">
						</div>
					</div>
					<!-- Add Arrows -->
					<div class="swiper-button-next"></div>
					<div class="swiper-button-prev"></div>
				</div>
			</section>
			<section class="section section-pkg">
				<div class="section-title">福利待遇</div>
				<div class="section-content"></div>
			</section>
			<section class="section section-contact">
				<div class="section-title">联系我们</div>
				<div class="section-content clearfix">
					<div class="contact-img">
						<img class="address-location" src="../assets/images/address-location.gif">
					</div>
					<div class="contact-text">
						<div class="contact-text-item">
							<p class="item-title">地址：</p>
							<p class="item-desc">合肥市庐阳区四里河路万科中心2007<br>安徽一美科技有限公司</p>
						</div>
						<div class="contact-text-item">
							<span class="item-title">邮编：</span>
							<span class="item-desc"> 230001</span>
						</div>
						<div class="contact-text-item">
							<span class="item-title">电话：</span>
							<span class="item-desc"> 0551-678830</span>
						</div>
						<div class="contact-text-item">
							<span class="item-title">商务合作：</span>
							<span class="item-desc"> app1mei@163.com</span>
						</div>
						<div class="contact-text-item">
							<span class="item-title">侵权投诉：</span>
							<span class="item-desc"> ah1mei@163.com</span>
						</div>
					</div>
				</div>
			</section>
			<div class="icon-kefu">
				<a href="javascript:void(0)" onclick="openJesongChatByGroup(33097,40372);return false;"></a>
			</div>
		</div>
	</div>
</template>

<script>
	import Swiper from 'swiper'
	export default {
		name: 'about',
		components: {},
		props: {},
		data() {
			return {}
		},
		computed: {},
		watch: {},
		created() {},
		mounted() {
			this.swiper = new Swiper('.swiper-container', {
				direction: 'horizontal',
				autoplay: true,
				slidesPerView: 3,
				spaceBetween: 30,
				centeredSlides: true,
				loop: true,
				navigation: {
					nextEl: '.swiper-button-next',
					prevEl: '.swiper-button-prev'
				}
			})
		},
		methods: {}
	}
</script>

<style lang="less" scoped>
	.header-black {
		background: #fff;
	}

	.clearfix {
		&:after {
			content: '';
			display: block;
			clear: both;
		}
	}

	.about-us {
		padding-top: 148px;
		min-height: 100%;
		height: auto;

		.section {
			text-align: center;
			color: #282828;
		}

		.section-title {
			font-size: 32px;
			font-weight: 500;
			line-height: 45px;
		}
	}

	.section-desc {
		.section-content {
			margin: 50px auto 120px;
			width: 1000px;
			word-wrap: break-word;
			word-break: break-all;
		}

		.contact-text {
			padding-top: 7px;
			text-align: left;

			p {
				font-size: 18px;
				line-height: 32px;
				margin-bottom: 32px;
			}
		}
	}

	.section-culture {
		margin-bottom: 120px;

		.culture-slogan {
			width: 1000px;
			height: 145px;
			margin: 70px auto 0;
			display: flex;
			align-items: center;
			justify-content: space-between;

			img {
				width: 100px;
				height: 100px;
				vertical-align: top;
			}

			p {
				margin-top: 11px;
				font-size: 18px;
				font-weight: 500;
				color: #282828;
			}
		}

		.swiper-container {
			width: 1300px;
			height: 300px;
			margin: 90px auto 0;
			padding: 15px 94px;
			background: #fcfcfc;
			--swiper-theme-color: #fcfcfc;
			--swiper-navigation-size: 30px;
			--swiper-navigation-color: #ddd;

			.swiper-slide {
				width: 360px;
				height: 270px;

				img {
					width: 360px;
					height: 270px;
				}
			}

		}
	}

	.section-pkg {
		.section-content {
			margin-top: 50px;
			height: 467px;
			background-image: url(../assets/images/section-pkg.png);
			background-repeat: no-repeat;
			background-position: center center;
			background-color: #fcfcfc;
			background-size: 1920px 467px;
		}
	}

	.section-contact {
		margin-top: 120px;
		padding-bottom: 105px;

		.section-content {
			width: 1240px;
			margin: 70px auto;

			.contact-img {
				float: left;
				width: 575px;
				height: 501px;
				margin-right: 180px;
				background: url(../assets/images/address-bg.png) no-repeat center center/cover;
				border-radius: 10px;
				position: relative;

				.address-location {
					width: 76px;
					height: 89px;
					position: absolute;
					top: 55%;
					left: 53%;
					transform: translate(-50%, -50%);
				}
			}

			.contact-text {
				float: left;
				text-align: left;
				margin-top: 50px;

				.contact-text-item {
					margin-bottom: 35px;

					.item-title {
						font-size: 20px;
						font-weight: 500;
						line-height: 38px;

						&:first-child {
							margin-bottom: 4px;
						}
					}

					.item-desc {
						font-size: 20px;
						line-height: 32px;
					}
				}
			}
		}
	}

	.icon-kefu {
		a {
			width: 142px;
			height: 172px;
			z-index: 200;
			position: fixed;
			top: 25%;
			right: 10%;

			&::after {
				content: "";
				width: 100%;
				height: 100%;
				background: url(../assets/images/icon-kefu.png) no-repeat;
				position: absolute;
			}
		}
	}
</style>
