<template>
	<div id="app">
		<router-view />
	</div>
</template>
 
<script>
	export default {
		name: 'App',
		data() {
			return {}
		},
		created() {},
		mounted() {},
		methods: {}
	}
</script>

<style lang="less">
	html,
	body,
	#app {
		width: 100%;
		height: 100%;
		font-size: 16px;
		color: #333;
		cursor: pointer;
	}

	.clearfix {
		&:after {
			content: '';
			display: block;
			clear: both;
		}
	}

	.header {
		width: 100%;
		min-width: 800px;
		height: 88px;
		// background: pink;
		z-index: 100;
		position: fixed;
		top: 0;
		left: 0;
	}

	.header-content {
		max-width: 1340px;
		height: 88px;
		margin: 0 auto;
		padding: 0 20px;
	}

	.header-logo {
		float: left;
		width: 231px;
		height: 60px;
		margin-top: 16px;
		background: url(assets/images/logo-white.png) no-repeat center center/231px 60px;

		.header-logo-link {
			display: inline-block;
			height: 100%;
			width: 100%;
			text-indent: -9999px;
		}
	}

	.header-nav {
		float: right;
		height: 100%;
		padding-top: 34px;

		a {
			float: left;
			line-height: 25px;
			margin-left: 48px;
			cursor: pointer;
			font-size: 18px;
			color: #FFFFFF;
			position: relative;

			&.router-link-exact-active {
				position: relative;

				&:after {
					content: "";
					width: 24px;
					height: 6px;
					background: #FFFFFF;
					border-radius: 3px;
					position: absolute;
					left: 50%;
					bottom: -13px;
					transform: translateX(-50%);
				}
			}
		}
	}

	.header-black {
		.header-logo {
			background: url(assets/images/logo-black.png) no-repeat center center/231px 60px;
		}

		.header-nav {
			a {
				color: #000;

				&.router-link-exact-active {
					&:after {
						background: #000;
					}
				}
			}
		}
	}
</style>
