<template>
  <div class="wrapper">
    <!-- 导航栏 header -->
    <header class="header header-black">
      <div class="header-content clearfix">
        <h1 class="header-logo">
          <a href="/" class="header-logo-link">一美助手</a>
        </h1>
        <div class="header-nav">
          <router-link to="/">首页</router-link>
          <router-link to="/project-baike">项目百科</router-link>
          <router-link to="/about">关于我们</router-link>
          <a
            href="https://www.zhipin.com/gongsir/7c04c90e9346fcd833V52dq0GQ~~.html?ka=company-jobs"
            target="view_window"
            >加入我们</a
          >
          <router-link to="/settled">机构入驻</router-link>
          <a
            href="javascript:void(0)"
            onclick="openJesongChatByGroup(33097,40372);return false;"
            >在线客服</a
          >
        </div>
      </div>
    </header>
    <!-- 步骤 -->
    <div class="settled">
      <div class="section section-desc">
        <div class="section-title">申请入驻</div>
        <div class="section-text">
          成功提交入驻申请资料后，工作人员会在3个工作日内完成审核并联系您洽谈合作方案
        </div>

        <div class="section-content clearfix">
          <div class="step" style="width: 85%; margin: 0 auto">
            <el-steps :active="active" align-center finish-status="success">
              <el-step title="选择机构类型"></el-step>
              <el-step title="填写基本资料"></el-step>
              <el-step title="上传资质材料"></el-step>
            </el-steps>
          </div>
          <!-- 第一步 -->
          <div class="steps1" style="margin-top: 22px">
            <el-collapse-transition>
              <div style="height: 100%" v-if="steps1">
                <el-form
                  :model="ruleForm1"
                  :rules="rules1"
                  ref="ruleForm1"
                  class="demo-ruleForm1"
                >
                  <!-- APP账号 -->
                  <el-form-item label="APP账号" prop="phone">
                    <el-row :gutter="10">
                      <el-col :span="22">
                        <el-input v-model="ruleForm1.phone" @focus="fnAccount"></el-input>
                        <span
                          style="color: #f56c6c; font-size: 12px"
                          v-if="isAccount === false"
                          >{{ errow }}</span
                        >
                      </el-col>
                      <el-col :span="2">
                        <div class="down_app">
                          下载APP <br />
                          注册账号
                          <a href="/" class="down_qrcode">
                            <img src="../assets/images/down_qrcode.png" />
                          </a>
                        </div>
                      </el-col>
                    </el-row>
                  </el-form-item>
                  <!-- 机构名称 -->
                  <el-form-item label=" 机构名称" prop="orgName">
                    <el-input v-model="ruleForm1.orgName"></el-input>
                  </el-form-item>
                  <!-- * 机构详细地址 -->
                  <el-form-item label=" 机构详细地址" prop="addrDetails">
                    <el-input v-model="ruleForm1.addrDetails"></el-input>
                  </el-form-item>
                  <!-- * 机构性质 -->
                  <el-form-item label="机构性质" prop="orgQuality">
                    <el-row>
                      <el-col :span="24">
                        <el-radio-group
                          fill="#E7FAF8"
                          text-color="#02BEBD"
                          v-model="ruleForm1.orgQuality"
                        >
                          <el-radio-button label="公立机构"></el-radio-button>
                          <el-radio-button label="民营机构"></el-radio-button>
                        </el-radio-group>
                      </el-col>
                    </el-row>
                  </el-form-item>
                  <!-- * 机构资质 -->
                  <el-form-item label="机构资质" prop="orgCertification">
                    <el-row>
                      <el-col :span="24">
                        <el-radio-group
                          fill="#E7FAF8"
                          text-color="#02BEBD"
                          v-model="ruleForm1.orgCertification"
                        >
                          <el-radio-button label="医院"></el-radio-button>
                          <el-radio-button label="门诊部"></el-radio-button>
                          <el-radio-button label="诊所"></el-radio-button>
                        </el-radio-group>
                      </el-col>
                    </el-row>
                  </el-form-item>
                  <!-- * 机构服务 -->
                  <el-form-item label="机构服务" prop="orgServe">
                    <el-row>
                      <el-col :span="24">
                        <el-checkbox-group
                          fill="#E7FAF8"
                          text-color="#02BEBD"
                          v-model="ruleForm1.orgServe"
                        >
                          <el-checkbox-button label="咨询服务" name="type">
                          </el-checkbox-button>
                          <el-checkbox-button label="地接服务" name="type">
                          </el-checkbox-button>
                          <el-checkbox-button
                            label="暂无"
                            name="type"
                          ></el-checkbox-button>
                        </el-checkbox-group>
                      </el-col>
                    </el-row>
                  </el-form-item>
                  <!-- * 返款周期 -->
                  <el-form-item label="返款周期" prop="orgPeriod">
                    <el-row>
                      <el-col :span="24">
                        <el-radio-group
                          fill="#E7FAF8"
                          text-color="#02BEBD"
                          v-model="ruleForm1.orgPeriod"
                        >
                          <el-radio-button label="返款日结"></el-radio-button>
                          <el-radio-button label="返款周结"></el-radio-button>
                          <el-radio-button label="返款月结"></el-radio-button>
                          <el-radio-button label="商议"></el-radio-button>
                        </el-radio-group>
                      </el-col>
                    </el-row>
                  </el-form-item>
                  <!-- 下一步按钮 -->
                  <el-form-item>
                    <el-row :gutter="20" type="flex">
                      <el-col :span="12" :offset="8">
                        <el-button type="success" round="" @click="fnNext1"
                          >下一步</el-button
                        >
                      </el-col>
                    </el-row>
                  </el-form-item>
                </el-form>
              </div>
            </el-collapse-transition>
          </div>
          <!-- 第二步 -->
          <div class="steps2" style="margin-top: 22px">
            <el-collapse-transition>
              <div class=" " v-if="steps2">
                <el-form
                  :model="ruleForm2"
                  :rules="rules2"
                  ref="ruleForm2"
                  class="demo-ruleForm2"
                >
                  <!--  * 项目平均分佣比 ( % )-->
                  <el-form-item label=" 项目平均分佣比 ( % )" prop="scale">
                    <el-input v-model="ruleForm2.scale"></el-input>
                  </el-form-item>
                  <!-- * 平均客单价 （ 元 ） -->
                  <el-form-item label=" 平均客单价 （ 元 ）" prop="ticketSales">
                    <el-input v-model="ruleForm2.ticketSales"></el-input>
                  </el-form-item>
                  <!-- * 医院机构介绍 -->
                  <el-form-item label=" 医院机构介绍" prop="remark">
                    <el-input type="textarea" v-model="ruleForm2.remark"></el-input>
                  </el-form-item>
                  <!-- * 合作模式介绍 -->
                  <el-form-item label=" 合作模式介绍" prop="cooperationDesc">
                    <el-input
                      type="textarea"
                      v-model="ruleForm2.cooperationDesc"
                    ></el-input>
                  </el-form-item>
                  <!-- * 热门项目 -->
                  <el-form-item label=" 热门项目" prop="xiangmu">
                    <el-row>
                      <el-col :span="24">
                        <div class="grid-content">
                          <el-row>
                            <el-col :span="4">
                              <div class="grid-content1 bg-purple">
                                <img
                                  src="../assets/images/add_img.png"
                                  @click="XMdialogVisible = true"
                                />
                              </div>
                            </el-col>
                            <el-col :span="20">
                              <div
                                class="grid-content2 bg-purple"
                                v-if="pictureList.projectList != 0"
                              >
                                <div
                                  class="dome"
                                  v-for="(item, index) in pictureList.projectList"
                                  :key="index"
                                >
                                  <el-tooltip
                                    class="item"
                                    effect="dark"
                                    :content="
                                      '项目名:' +
                                      item.projectName +
                                      ',' +
                                      '客单价:' +
                                      item.projectMoney +
                                      ',' +
                                      '分佣比:' +
                                      item.projectScale
                                    "
                                    placement="top"
                                  >
                                    <img class="dome_img" :src="item.projectPics" />
                                  </el-tooltip>
                                  <i
                                    class="el-icon-close"
                                    @click="deleProjectPics(item)"
                                  ></i>
                                </div>
                              </div>
                            </el-col>
                          </el-row>
                        </div>
                      </el-col>
                    </el-row>
                  </el-form-item>
                  <!-- * 医院环境 -->
                  <el-form-item label=" 医院环境" prop="huanjing">
                    <el-row>
                      <el-col :span="24">
                        <div class="grid-content">
                          <el-row>
                            <el-col :span="4">
                              <div class="grid-content1 bg-purple">
                                <img
                                  src="../assets/images/add_img.png"
                                  @click="HJdialogVisible = true"
                                />
                              </div>
                            </el-col>
                            <el-col :span="20">
                              <div
                                class="grid-content2 bg-purple"
                                v-if="pictureList.emList != 0"
                              >
                                <div
                                  class="dome"
                                  v-for="(item, index) in pictureList.emList"
                                  :key="index"
                                >
                                  <el-tooltip
                                    class="item"
                                    effect="dark"
                                    :content="'地点:' + item.addrName"
                                    placement="top"
                                  >
                                    <img class="dome_img" :src="item.addrPics" />
                                  </el-tooltip>
                                  <i
                                    class="el-icon-close"
                                    @click="deleAddrPics(item)"
                                  ></i>
                                </div>
                              </div>
                            </el-col>
                          </el-row>
                        </div>
                      </el-col>
                    </el-row>
                  </el-form-item>
                  <!-- 下一步按钮 -->
                  <el-form-item>
                    <el-row :gutter="80" type="flex">
                      <el-col :span="6" :offset="6">
                        <el-button type="info" round="" @click="fnBack">上一步</el-button>
                      </el-col>
                      <el-col :span="6">
                        <el-button type="success" round="" @click="fnNext2"
                          >下一步</el-button
                        >
                      </el-col>
                    </el-row>
                  </el-form-item>
                </el-form>
              </div>
            </el-collapse-transition>
          </div>
          <!-- 添加热门项目弹窗 -->
          <el-dialog title="热门项目" :visible.sync="XMdialogVisible" width="40%">
            <el-form
              :model="XMruleForm"
              :rules="XMrules"
              ref="XMruleForm"
              class="demo-XMruleForm"
            >
              <!-- * 项目名称 -->
              <el-form-item label="项目名称" prop="projectName">
                <el-input
                  v-model="XMruleForm.projectName"
                  placeholder="请输入项目名称"
                ></el-input>
              </el-form-item>
              <!-- * 项目客单价 -->
              <el-form-item label="项目客单价" prop="projectMoney">
                <el-input
                  v-model="XMruleForm.projectMoney"
                  placeholder="请输入项目客单价 （ 元 ）"
                ></el-input>
              </el-form-item>
              <!-- * 项目分佣比 -->
              <el-form-item label="项目分佣比" prop="projectScale">
                <el-input
                  v-model="XMruleForm.projectScale"
                  placeholder="请输入项目分佣比 （ % ）"
                ></el-input>
              </el-form-item>
              <!-- 项目图片 -->
              <div class="projectPics">
                <label style="font-size: 18px; margin-right: 20px"
                  ><span style="color: #f56c6c; margin-right: 4px">*</span>项目图片</label
                >
                <input
                  type="file"
                  id="Proputfile"
                  ref="Proinputer"
                  value="添加图片"
                  accept="/image/jpge,image/jpeg,image/jpg,image/png"
                  multiple="multiple"
                  @change="fileUpload"
                />
              </div>
            </el-form>

            <span slot="footer" class="dialog-footer">
              <el-button type="primary" size="mini" @click="addProject">添 加</el-button>
            </span>
          </el-dialog>
          <!-- 添加医院环境弹窗  -->
          <el-dialog title="医院环境" :visible.sync="HJdialogVisible" width="40%">
            <el-form
              :model="HJruleForm"
              :rules="HJrules"
              ref="HJruleForm"
              class="demo-HJruleForm"
            >
              <!-- 环境地点名称 -->
              <el-form-item label="环境地点名称" prop="addrName">
                <el-input
                  v-model="HJruleForm.addrName"
                  placeholder="请输入环境地点名称"
                ></el-input>
              </el-form-item>
              <!-- 环境图片 -->
              <div class="projectPics">
                <label style="font-size: 18px; margin-right: 20px"
                  ><span style="color: #f56c6c; margin-right: 4px">*</span>医院环境</label
                >
                <input
                  type="file"
                  id="Envputfile"
                  ref="Envinputer"
                  value="添加图片"
                  accept="image/jpge,image/jpeg,image/jpg,image/png"
                  multiple="multiple"
                  @change="fileUpload2"
                />
              </div>
            </el-form>
            <span slot="footer" class="dialog-footer">
              <el-button type="primary" size="mini" @click="addEnvironment"
                >添 加</el-button
              >
            </span>
          </el-dialog>
          <!-- 第3步 -->
          <div class="steps3" style="margin-top: 22px">
            <el-collapse-transition>
              <div class="" v-if="steps3">
                <el-form
                  :model="ruleForm3"
                  :rules="rules3"
                  ref="ruleForm3"
                  class="demo-XMruleForm3"
                >
                  <div class="projectPics" style="margin-bottom: 10px">
                    <label style="font-size: 18px; margin-right: 20px">
                      <span style="color: #f56c6c; margin-right: 4px">*</span>资质证件
                    </label>
                    <router-link class="example" tag="a" target="_blank" to="/example"
                      >查看示例
                    </router-link>
                  </div>
                  <el-row>
                    <el-col :span="24">
                      <el-row :gutter="40">
                        <el-col :span="7">
                          <div class="bg-purple1">
                            <div
                              class=""
                              v-if="
                                pictureList.licenseKey != null &&
                                pictureList.licenseKey != '-'
                              "
                            >
                              <i class="el-icon-close" @click="deleZZPic('2')"></i>
                              <img class="picture" :src="pictureList.licenseKey" />
                            </div>
                            <el-form-item prop="licenseKey" v-else>
                              <img
                                src="../assets/images/add_img.png"
                                @click="ykzdialogVisible = true"
                              />
                            </el-form-item>
                            <span style="font-size: 14px">医疗机构执业许可证</span>
                          </div>
                        </el-col>

                        <el-col :span="7">
                          <div class="bg-purple1">
                            <div
                              class=""
                              v-if="
                                pictureList.licensePic != null &&
                                pictureList.licensePic != '-'
                              "
                            >
                              <i class="el-icon-close" @click="deleZZPic('1')"></i>
                              <img class="picture" :src="pictureList.licensePic" />
                            </div>
                            <el-form-item prop="licensePic" v-else>
                              <img
                                class="picture"
                                src="../assets/images/add_img.png"
                                @click="YYdialogVisible = true"
                              />
                            </el-form-item>
                            <span style="font-size: 14px"
                              >营业执照or民办非企业单位证书</span
                            >
                          </div>
                        </el-col>

                        <el-col :span="7">
                          <div class="bg-purple1">
                            <div
                              class=""
                              v-if="
                                pictureList.certificatePic != null &&
                                pictureList.certificatePic != '-'
                              "
                            >
                              <i class="el-icon-close" @click="deleZZPic('3')"></i>
                              <img class="picture" :src="pictureList.certificatePic" />
                            </div>
                            <el-form-item prop="certificatePic" v-else>
                              <img
                                src="../assets/images/add_img.png"
                                @click="ZMdialogVisible = true"
                              />
                            </el-form-item>
                            <span style="font-size: 14px">在院工作证明</span>
                          </div>
                        </el-col>
                      </el-row>
                    </el-col>
                  </el-row>

                  <div class="" style="margin: 35px 0 12px 0">
                    <p style="font-size: 20px; font-weight: 600">联系人信息</p>
                    <span>联系人信息用来对渠道方展示、沟通信息时所用</span>
                  </div>
                  <el-form-item label="联系人姓名" prop="relationName">
                    <el-input
                      v-model="ruleForm3.relationName"
                      placeholder="请填写医院/机构联系人姓名"
                    >
                    </el-input>
                  </el-form-item>
                  <el-form-item label="联系人性别" prop="relationSex">
                    <el-row>
                      <el-col :span="24">
                        <el-radio-group
                          fill="#E7FAF8"
                          text-color="#02BEBD"
                          v-model="ruleForm3.relationSex"
                        >
                          <el-radio-button label="1">男</el-radio-button>
                          <el-radio-button label="2">女</el-radio-button>
                        </el-radio-group>
                      </el-col>
                    </el-row>
                  </el-form-item>
                  <el-form-item label="联系人手机" prop="relationPhone">
                    <el-input
                      v-model="ruleForm3.relationPhone"
                      placeholder="请填写医院/机构联系人手机"
                    >
                    </el-input>
                  </el-form-item>
                  <el-form-item label="联系人微信" prop="relationWx">
                    <el-input
                      v-model="ruleForm3.relationWx"
                      placeholder="请填写医院/机构联系人手机"
                    >
                    </el-input>
                  </el-form-item>
                  <el-form-item>
                    <el-row :gutter="80" type="flex">
                      <el-col :span="6" :offset="6">
                        <el-button type="info" round="" @click="fnBack2"
                          >上一步</el-button
                        >
                      </el-col>
                      <el-col :span="6">
                        <el-button type="success" round="" @click="fnSubmit"
                          >提交
                        </el-button>
                      </el-col>
                    </el-row>
                  </el-form-item>
                </el-form>
              </div>
            </el-collapse-transition>
          </div>
          <!-- 营业执照或单位证书 -->
          <el-dialog
            title="营业执照或单位证书"
            :visible.sync="YYdialogVisible"
            width="40%"
          >
            <div class="projectPics">
              <input
                type="file"
                id="YYputfile"
                ref="YYinputer"
                value="添加图片"
                accept="/image/jpge,image/jpeg,image/jpg,image/png"
                multiple="multiple"
                @change="fnLicensePic"
              />
            </div>
            <h3 style="color: #f56c6c; margin-top: 22px">
              注意事项：
              <p>1，文件格式支持：png，PNG，jpg，JPG，jpeg，JPEG；</p>
              <p>2，上传的文件过程中，运行缓慢，请耐心等待，切勿重复点击；</p>
            </h3>
            <span slot="footer" class="dialog-footer">
              <el-button type="primary" size="mini" @click="addPermit('1')"
                >添 加</el-button
              >
            </span>
          </el-dialog>
          <!-- 医疗机构执业许可证 -->
          <el-dialog
            title="医疗机构执业许可证"
            :visible.sync="ykzdialogVisible"
            width="40%"
          >
            <div class="projectPics">
              <input
                type="file"
                id="YKZputfile"
                ref="YKZinputer"
                value="添加图片"
                accept="/image/jpge,image/jpeg,image/jpg,image/png"
                multiple="multiple"
                @change="fnLicenseKey"
              />
            </div>
            <h3 style="color: #f56c6c; margin-top: 22px">
              注意事项：
              <p>1，文件格式支持：png，PNG，jpg，JPG，jpeg，JPEG；</p>
              <p>2，上传的文件过程中，运行缓慢，请耐心等待，切勿重复点击；</p>
            </h3>
            <span slot="footer" class="dialog-footer">
              <el-button type="primary" size="mini" @click="addPermit('2')"
                >添 加</el-button
              >
            </span>
          </el-dialog>
          <!-- 在院工作证明 -->
          <el-dialog title="在院工作证明" :visible.sync="ZMdialogVisible" width="40%">
            <div class="projectPics">
              <input
                type="file"
                id="ZMputfile"
                ref="ZMinputer"
                value="添加图片"
                accept="/image/jpge,image/jpeg,image/jpg,image/png"
                multiple="multiple"
                @change="fnCertificatePic"
              />
            </div>
            <h3 style="color: #f56c6c; margin-top: 22px">
              注意事项：
              <p>1，文件格式支持：png，PNG，jpg，JPG，jpeg，JPEG；</p>
              <p>2，上传的文件过程中，运行缓慢，请耐心等待，切勿重复点击；</p>
            </h3>
            <span slot="footer" class="dialog-footer">
              <el-button type="primary" size="mini" @click="addPermit('3')"
                >添 加</el-button
              >
            </span>
          </el-dialog>
        </div>
      </div>
    </div>

    <!-- 底部footer  -->
    <div class="footer">
      <div class="content-filing">
        <p class="content-title">安徽一美科技有限公司</p>
        <p>皖网文（2019）6047-305号 <span @click="handleICP">皖ICP备15026816号</span></p>
      </div>
    </div>
  </div>
</template>

<script>
import {
  insertWebOrgInfoOne,
  insertWebOrgInfoTwo,
  insertWebOrgInfoLast,
  queryWebOrgInfor,
  deleteWebProjectById,
  deleteWebEmById,
  deleteWebOrgImg,
} from "../api/settled.js";
export default {
  name: "settled",
  components: {},
  props: {},
  data() {
    // App账号校验规则
    const checkMobile = (rule, value, callback) => {
      const Reg = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
      if (Reg.test(value) === false) {
        callback(new Error("请输入正确的手机号"));
      } else {
        return callback();
      }
    };
    // 项目平均分佣比(%)验证
    const checkScale = (rule, value, callback) => {
      const Reg = /^100$|^[1-9]\d?(\.\d{1,9})*$/;
      if (Reg.test(value) === false) {
        callback(new Error("分佣比可输入范围为1-100"));
      } else {
        return callback();
      }
    };
    // 平均客单价 （ 元 ）验证
    const ticketSales = (rule, value, callback) => {
      const Reg = /^[1-9]\d{2,}[.]?\d*/;
      if (Reg.test(value) === false) {
        callback(new Error("客单价最小为100元"));
      } else {
        return callback();
      }
    };
    return {
      errow: "", // 错误提示信息
      orgId: "", // insertWebOrgInfoOne 接口中返的对象中的主键id
      pictureList: "", // queryWebOrgInfor接口返回信息
      active: 0, // steps 参数
      steps1: true, // 控制第一步
      ruleForm1: {
        // 第1步参数
        phone: "", // App账号
        orgName: "", // 机构名称
        addrDetails: "", // 机构详细地址
        orgQuality: "", // 机构性质
        orgCertification: "", // 机构资质
        orgServe: [], // 机构服务
        orgPeriod: "", // 返款周期
      },
      isAccount: true, // App账号是否存在 false 存在 true 存在
      rules1: {
        // 第1不验证
        phone: [
          { required: true, message: "请输入APP账号", trigger: "blur" },
          { validator: checkMobile, trigger: "blur" },
        ],
        orgName: [{ required: true, message: "请输入机构名称", trigger: "blur" }],
        addrDetails: [{ required: true, message: "请输入机构详细地址", trigger: "blur" }],
        orgQuality: [{ required: true, message: "请选择机构性质", trigger: "change" }],
        orgCertification: [
          { required: true, message: "请选择机构资质", trigger: "change" },
        ],
        orgServe: [{ required: true, message: "请选择机构服务", trigger: "change" }],
        orgPeriod: [{ required: true, message: "请选择返款周期", trigger: "change" }],
      },
      steps2: false, // 控制第二步
      ruleForm2: {
        // 第2步参数
        scale: "", // 项目平均分佣比 ( % )
        ticketSales: "", // 平均客单价 （ 元 ）
        remark: "", //  医院机构介绍
        cooperationDesc: "", // 合作模式介绍
      },
      rules2: {
        // 第2不验证
        scale: [
          { required: true, message: "请输入项目平均分佣比 ( % ) ", trigger: "blur" },
          { validator: checkScale, trigger: "blur" },
        ],
        ticketSales: [
          { required: true, message: "请输入平均客单价 （ 元 ）", trigger: "blur" },
          { validator: ticketSales, trigger: "blur" },
        ],
        remark: [{ required: true, message: "请输入医院机构介绍", trigger: "blur" }],
        cooperationDesc: [
          { required: true, message: "请输入合作模式介绍", trigger: "blur" },
        ],
      },
      XMdialogVisible: false, // 控制添加项目弹窗
      XMruleForm: {
        // 项目参数
        projectName: "", // 项目名称
        projectMoney: "", // 项目客单价
        projectScale: "", // 项目分佣比
        projectPics: "", // 项目图片
        file: "", // 上传图片
      },
      XMrules: {
        // 项目验证
        projectName: [{ required: true, message: "请输入项目名称", trigger: "blur" }],
        projectMoney: [
          { required: true, message: "请输入项目客单价", trigger: "blur" },
          { validator: ticketSales, trigger: "blur" },
        ],
        projectScale: [
          { required: true, message: "请输入项目平均分佣比( % )", trigger: "blur" },
          { validator: checkScale, trigger: "blur" },
        ],
      },
      HJdialogVisible: false, // 控制医院环境弹窗
      HJruleForm: {
        // 医院环境参数
        addrName: "", // 环境地点名称
        addrPics: "", // 医院环境
        file: "", // 上传图片
      },
      HJrules: {
        // 医院环境验证
        addrName: [{ required: true, message: "请输入环境地点名称", trigger: "blur" }],
      },
      steps3: false, // 控制第三步
      YYdialogVisible: false, // 控制上传营业执照或单位证书
      ykzdialogVisible: false, // 医疗机构执业许可证
      ZMdialogVisible: false, // 在院工作证明
      ruleForm3: {
        // 上传资质信息
        relationName: "", // 联系人姓名
        relationSex: "", // 联系人性别
        relationPhone: "", // 联系人手机
        relationWx: "", // 联系人微信
        licensePic: "", // 营业执照
        licenseKey: "", // 执业许可证
        certificatePic: "", // 工作证明
      },
      rules3: {
        // 上传资质信息验证
        licensePic: [
          { required: true, message: "请上传营业执照或单位证书", trigger: "change" },
        ],
        licenseKey: [
          { required: true, message: "医疗机构执业许可证", trigger: "change" },
        ],
        certificatePic: [{ required: true, message: "在院工作证明", trigger: "change" }],
        relationName: [
          { required: true, message: "请填写医院/机构联系人姓名", trigger: "blur" },
        ],
        relationSex: [{ required: true, message: "请选择联系人性别", trigger: "change" }],
        relationPhone: [
          { required: true, message: "请填写医院/机构联系人手机", trigger: "blur" },
          { validator: checkMobile, trigger: "blur" },
        ],
        relationWx: [
          { required: true, message: "请填写医院/机构联系人微信", trigger: "blur" },
        ],
      },
      gotop: false,
    };
  },
  computed: {},
  watch: {},
  created() {
    this.getPicList();
  },
  mounted() {
    // 此处true 需要加上 不加滚动事件可能绑定不成功
    window.addEventListener("scroll", this.handleScroll, true);
  },
  methods: {
    // 返回顶部
    handleScroll() {
      const scrolltop = document.documentElement.scrolltop || document.body.scrollTop;
      scrolltop > 30 ? (this.gotop = true) : (this.gotop = false);
    },
    toTop() {
      let top = document.documentElement.scrollTop || document.body.scrollTop;
      // 实现滚动效果
      const timeTop = setInterval(() => {
        document.body.scrollTop = document.documentElement.scrollTop = top -= 50;
        if (top <= 0) {
          clearInterval(timeTop);
        }
      }, 10);
    },

    // App账号输入
    fnAccount() {
      this.isAccount = true;
    },
    // steps--第一步
    fnNext1() {
      this.$refs.ruleForm1.validate((valid) => {
        if (valid) {
          const permission = this.ruleForm1.orgServe.join(",");
          insertWebOrgInfoOne({
            addrDetails: this.ruleForm1.addrDetails, // 详细地址信息
            orgCertification: this.ruleForm1.orgCertification, // 机构资质
            orgName: this.ruleForm1.orgName, // 机构名称
            orgPeriod: this.ruleForm1.orgPeriod, // 返还周期
            orgQuality: this.ruleForm1.orgQuality, // 机构性质
            orgServe: permission, // 特色服务(多选，逗号隔开)
            phone: this.ruleForm1.phone, // APP账号
          })
            .then((res) => {
              this.toTop();
              if (res.data.code === 0) {
                this.orgId = res.data.data.id;
                this.active = 1;
                this.steps1 = false;
                this.steps2 = true;
              } else {
                this.isAccount = false;
                this.errow = res.data.msg;
                this.$message.error(res.data.msg);
              }
            })
            .catch((err) => {
              console.log(err);
            });
        } else {
        }
      });
    },
    // steps--第二步
    fnNext2() {
      this.$refs.ruleForm2.validate((valid) => {
        if (valid) {
          insertWebOrgInfoTwo({
            orgId: this.orgId, // insertWebOrgInfoOne 接口中返的对象中的主键id
            cooperationDesc: this.ruleForm2.cooperationDesc, // 合作模式介绍
            remark: this.ruleForm2.remark, // 医院机构介绍
            scale: this.ruleForm2.scale, // 项目平均分佣比( % )
            ticketSales: this.ruleForm2.ticketSales, // 平均客单价
          })
            .then((res) => {
              this.toTop();
              if (res.data.code === 0) {
                this.active = 2;
                this.steps2 = false;
                this.steps3 = true;
              }
            })
            .catch((err) => {
              console.log(err);
            });
        }
      });
    },
    // 热门项目选择图片
    fileUpload(event) {
      this.XMruleForm.file = event.target.files;
    },
    // 添加热门项目
    addProject() {
      this.$refs.XMruleForm.validate((valid) => {
        if (valid) {
          const formData = new FormData();
          formData.append("orgId", this.orgId);
          formData.append("projectMoney", this.XMruleForm.projectMoney);
          formData.append("projectName", this.XMruleForm.projectName);
          formData.append("projectScale", this.XMruleForm.projectScale);
          formData.append("projectPics", this.XMruleForm.file[0]);
          this.$axios({
            //url: "http://112.31.191.92:909/ymzs/api/web/organization/insertWebOrgInfoProject",
            url: "https://api.11mei.cn/ymzs/api/web/organization/insertWebOrgInfoProject",
            method: "POST",
            headers: {
              "Content-Type": "multipart/form-data",
            },
            data: formData,
          })
            .then((res) => {
              if (res.data.code === 0) {
                this.XMdialogVisible = false;
                this.getPicList();
              }
            })
            .catch((e) => {});
        }
      });
    },
    // 医院环境选择图片
    fileUpload2(event) {
      this.HJruleForm.file = event.target.files;
    },
    // 添加医院环境图片
    addEnvironment() {
      this.$refs.HJruleForm.validate((valid) => {
        if (valid) {
          const formData = new FormData();
          formData.append("orgId", this.orgId);
          formData.append("addrName", this.HJruleForm.addrName);
          formData.append("addrPics", this.HJruleForm.file[0]);
          this.$axios({
            url: "https://api.11mei.cn/ymzs/api/web/organization/insertWebOrgInfoEm",
            // url: 'http://112.31.191.92:909/ymzs/api/web/organization/insertWebOrgInfoEm',
            method: "POST",
            headers: {
              "Content-Type": "multipart/form-data",
            },
            data: formData,
          })
            .then((res) => {
              if (res.data.code === 0) {
                this.HJdialogVisible = false;
                this.getPicList();
              }
            })
            .catch((err) => {
              console.log(err);
            });
        }
      });
    },
    //  删除热门项目
    deleProjectPics(item) {
      deleteWebProjectById({
        projectId: item.id,
      })
        .then((res) => {
          if (res.data.code === 0) {
            this.getPicList();
            this.$message.success(res.data.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 删除医院环境图片
    deleAddrPics(item) {
      deleteWebEmById({
        emId: item.id,
      })
        .then((res) => {
          if (res.data.code === 0) {
            this.getPicList();
            this.$message.success(res.data.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 上传营业执照or民办非企业单位证书
    fnLicensePic(event) {
      this.ruleForm3.licensePic = event.target.files;
    },
    //  上传医疗机构执业许可证
    fnLicenseKey(event) {
      this.ruleForm3.licenseKey = event.target.files;
    },
    // 上传  在院工作证明
    fnCertificatePic(event) {
      this.ruleForm3.certificatePic = event.target.files;
    },
    // 添加营业执照/许可证和工作证明等
    addPermit(type) {
      const formData = new FormData();
      if (type === "1") {
        formData.append("file", this.ruleForm3.licensePic[0]);
      } else if (type === "2") {
        formData.append("file", this.ruleForm3.licenseKey[0]);
      } else if (type === "3") {
        formData.append("file", this.ruleForm3.certificatePic[0]);
      }
      formData.append("orgId", this.orgId);
      formData.append("type", type);
      this.$axios({
        url: "https://api.11mei.cn/ymzs/api/web/organization/insertWebOrgInfoCertificate",
        // url: "http://112.31.191.92:909/ymzs/api/web/organization/insertWebOrgInfoCertificate",
        method: "POST",
        headers: {
          "Content-Type": "multipart/form-data",
        },
        data: formData,
      })
        .then((res) => {
          if (res.data.code === 0) {
            this.YYdialogVisible = false;
            this.ykzdialogVisible = false;
            this.ZMdialogVisible = false;
            this.getPicList();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 第3步--提交
    fnSubmit() {
      this.$refs.ruleForm3.validate((valid) => {
        if (valid) {
          insertWebOrgInfoLast({
            orgId: this.orgId,
            relationName: this.ruleForm3.relationName,
            relationSex: this.ruleForm3.relationSex,
            relationPhone: this.ruleForm3.relationPhone,
            relationWx: this.ruleForm3.relationWx,
          })
            .then((res) => {
              this.toTop();
              if (res.data.code === 0) {
                this.active = 3;
                this.$message.success(res.data.msg);
                setTimeout(() => {
                  this.$router.go(0);
                }, 2000);
              }
            })
            .catch((err) => {
              console.log(err);
            });
        }
      });
    },
    // 删除营业执照/许可证/工作证明等
    deleZZPic(type) {
      deleteWebOrgImg({
        type: type,
        imgIndex: 0,
        orgId: this.orgId,
      }).then((res) => {
        console.log(res);
        if (res.data.code === 0) {
          this.getPicList();
          this.$message.success(res.data.msg);
        }
      });
    },
    // 一美助手官网--机构信息数据回显（机构中所有的信息：包括热门项目 + 医院环境 等）
    getPicList() {
      queryWebOrgInfor({
        phone: this.ruleForm1.phone, // APP账号
      })
        .then((res) => {
          console.log(res);
          if (res.data.code === 0) {
            this.pictureList = res.data.data;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 填写第2步内容的 --- 上一步
    fnBack() {
      this.active = 0;
      this.steps1 = true;
      this.steps2 = false;
      this.toTop();
    },
    // 填写第3步内容的 --- 上一步
    fnBack2() {
      this.active = 1;
      this.steps2 = true;
      this.steps3 = false;
      this.toTop();
    },
    // 跳转备案
    handleICP() {
      window.open("https://beian.miit.gov.cn/", "_blank");
    },
    // 查看示例
    fnExample() {
      const target = this.$refs.example;
      target.setAttribute("href", window.location.origin + "/views/example");
      target.click();
    },
  },
};
</script>

<style lang="less" scoped>
@import "./settled.less";
</style>
