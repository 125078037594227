<template>
  <div class="wrapper">
    <div class="about">
      <el-row :gutter="40">
        <el-col :span="8">
          <div class="common">
            <h3 class="">营业执照or民办非企业单位证书：</h3>
            <div class="common-box">
              <el-image
                class="papers"
                src="https://www.11mei.cn/img/example/yyzz.png"
                fit="fit"
                :preview-src-list="srcList1"
              >
              </el-image>
              <p class="aui-text-center" style="color: #bbbbc8">
                点击查看营业执照or民办非企业单位证书
              </p>
            </div>
          </div>
        </el-col>

        <el-col :span="8">
          <div class="common">
            <h3 class="">医疗机构执业许可证：</h3>
            <div class="common-box">
              <el-image
                class="papers"
                src="https://www.11mei.cn/img/example/ykz.png"
                :preview-src-list="srcList2"
              >
              </el-image>
              <p class="aui-text-center" style="color: #bbbbc8">
                点击查看医疗机构执业许可证
              </p>
            </div>
          </div>
        </el-col>
        <el-col :span="8">
          <div class="common">
            <div class="entrust">
              <h3 class="">在院工作证明：</h3>
              <h3 style="color: #1d51e0" @click="fnDownLoad">下载模版</h3>
            </div>
            <div class="common-box">
              <el-image
                class="papers"
                src="https://www.11mei.cn/img/example/zm.png"
                :preview-src-list="srcList3"
              >
              </el-image>
              <p class="aui-text-center" style="color: #bbbbc8">点击查看在院工作证明</p>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
export default {
  name: "",
  components: {},
  props: {},
  data() {
    return {
      srcList1: ["https://www.11mei.cn/img/example/yyzz_big.png"],
      srcList2: ["https://www.11mei.cn/img/example/ykz_big.jpg"],
      srcList3: ["https://www.11mei.cn/img/example/zm_big.png"],
    };
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  methods: {
    fnDownLoad() {
      window.location.href = "https://www.11mei.cn/img/example/zhengMing.docx";
    },
  },
};
</script>

<style lang="less" scoped>
.about {
  width: 1500px;
  margin: 0 auto;
  padding-top: 50px;
  box-sizing: border-box;
}

.common-box {
  width: 100%;
  border: 2px dashed #d3d9e5;
  border-radius: 5px;
  margin-top: 20px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.papers {
  width: 100%;
  height: 280px;
  margin-bottom: 10px;
}

.entrust {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.avatar-uploader /deep/ .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  background-color: #fff;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  width: 180px;
  height: 180px;
}

.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

.showUploader /deep/ .el-upload-list {
  display: none;
}

.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>
