import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/home.vue'

Vue.use(VueRouter)

const routes = [{
		path: '/',
		name: 'Home',
		component: Home
	},
	{
		path: '/project-baike',
		name: 'Baike',
		component: () => import('../views/project-baike.vue')
	},
	{
		path: '/about',
		name: 'About',
		component: () => import('../views/about.vue')
	},
	{
		path: '/settled',
		name: 'Settled',
		component: () => import('../views/settled.vue')
	},
	{
		path: '/example',
		name: 'example',
		component: () => import('../views/example.vue')
	}
]

const router = new VueRouter({
	routes
})

export default router
