import request from '@/utils/request.js'

// 一美助手官网 填写机构信息1-1:机构名称<--至-->返还周期
export const insertWebOrgInfoOne = data => {
	return request({
		url: '/ymzs/api/web/organization/insertWebOrgInfoOne',
		method: 'POST',
		data: data
	})
}

// 一美助手官网 填写机构信息：分佣比<--至-->医院环境
export const insertWebOrgInfoTwo = data => {
	return request({
		url: '/ymzs/api/web/organization/insertWebOrgInfoTwo',
		method: 'POST',
		data: data
	})
}

// 一美助手官网 填写机构信息中的添加热门项目的信息
export const insertWebOrgInfoProject = data => {
	return request({
		url: '/ymzs/api/web/organization/insertWebOrgInfoProject',
		method: 'POST',
		data: data
	})
}

// 一美助手官网--机构信息数据回显（机构中所有的信息：包括热门项目 + 医院环境 等
export const queryWebOrgInfor = data => {
	return request({
		url: '/ymzs/api/web/organization/queryWebOrgInfor',
		method: 'POST',
		data: data
	})
}

//  一美助手官网 点击‘X’删除机构中的热门项目：热门项目没有编辑功能，不符合的删除再上传
export const deleteWebProjectById = data => {
	return request({
		url: '/ymzs/api/web/organization/deleteWebProjectById',
		method: 'POST',
		data: data
	})
}

// 一美助手官网 填写机构信中的医院环境的信息
export const insertWebOrgInfoEm = data => {
	return request({
		url: '/ymzs/api/web/organization/insertWebOrgInfoEm',
		method: 'POST',
		data: data
	})
}

// 一美助手官网 点击'X'删除医院环境数据：不存在编辑，不喜欢的直接删除再上传
export const deleteWebEmById = data => {
	return request({
		url: '/ymzs/api/web/organization/deleteWebEmById',
		method: 'POST',
		data: data
	})
}

// 一美助手官网 填写机构信息:资质证件<--至-->联系人微信 录入资质 + 联系人相关信息
export const insertWebOrgInfoLast = data => {
	return request({
		url: '/ymzs/api/web/organization/insertWebOrgInfoLast',
		method: 'POST',
		data: data
	})
}

// 一美助手官网 点击'X'删除机构 营业执照，医疗许可证，工作证明等
export const deleteWebOrgImg = data => {
	return request({
		url: '/ymzs/api/web/organization/deleteWebOrgImg',
		method: 'POST',
		data: data
	})
}
