<template>
	<div class="wrapper">
		<!-- 导航栏 header -->
		<header class="header" :class="navFlag?'header-black':''">
			<div class="header-content clearfix">
				<h1 class="header-logo">
					<a href="/" class="header-logo-link">一美助手</a>
				</h1>
				<div class="header-nav">
					<router-link to="/">首页</router-link>
					<router-link to="/project-baike">项目百科</router-link>
					<router-link to="/about">关于我们</router-link>
					<a href="https://www.zhipin.com/gongsir/7c04c90e9346fcd833V52dq0GQ~~.html?ka=company-jobs"
						target="view_window">加入我们</a>
					<router-link to="/settled">机构入驻</router-link>
					<a href="javascript:void(0)" onclick="openJesongChatByGroup(33097,40372);return false;">在线客服</a>
				</div>
			</div>
		</header>
		<!-- swiper 页面 -->
		<div class="swiper-container">
			<div class="swiper-wrapper">
				<!-- 首页 section-index -->
				<div class="swiper-slide section section-index">
					<div class="section-content clearfix">
						<div class="section-left">
							<div class="section-phone ani" swiper-animate-effect="zoomIn" swiper-animate-duration="1s"
								swiper-animate-delay="0s"></div>
							<div class="section-icon ani icon-1" swiper-animate-effect="zoomIn"
								swiper-animate-duration="1s" swiper-animate-delay="0.5s"></div>
							<div class="section-icon ani icon-2" swiper-animate-effect="zoomIn"
								swiper-animate-duration="1s" swiper-animate-delay="0.5s"></div>
							<div class="section-icon ani icon-3" swiper-animate-effect="zoomIn"
								swiper-animate-duration="1s" swiper-animate-delay="0.5s"></div>
						</div>
						<div class="section-right">
							<div class="index-right-title ani" swiper-animate-effect="fadeInUp"
								swiper-animate-duration="1s" swiper-animate-delay="0s">
							</div>
							<div class="index-right-sub ani" swiper-animate-effect="fadeInUp"
								swiper-animate-duration="1s" swiper-animate-delay="0.5s">
								<p>海量优质案例，丰富营销话术</p>
								<p>提升私域转化，赋能医美营销</p>
							</div>
							<div class="index-right-desc ani clearfix" swiper-animate-effect="fadeInUp"
								swiper-animate-duration="1s" swiper-animate-delay="1s">
								<a href="/" class="icon-ios">
									<img src="../assets/images/icon-qrcode-ios.png">
								</a>
								<a href="/" class="icon-android">
									<img src="../assets/images/icon-qrcode-android.png">
								</a>
							</div>
						</div>
					</div>
				</div>
				<!-- 案例 section-case -->
				<div class="swiper-slide section section-case">
					<div class="section-content clearfix">
						<img class="ani" swiper-animate-effect="fadeInUp" swiper-animate-duration="2s"
							swiper-animate-delay="0" src="../assets/images/section-case-bg.png">
					</div>
				</div>
				<!-- 精选  section-choiceness -->
				<div class="swiper-slide section section-choiceness">
					<div class="section-content clearfix">
						<div class="section-left">
							<div class="section-phone-content ani" swiper-animate-effect="zoomIn"
								swiper-animate-duration="1s" swiper-animate-delay="0s"></div>
							<div class="section-phone ani" swiper-animate-effect="zoomIn" swiper-animate-duration="1s"
								swiper-animate-delay="0s"></div>
							<div class="section-icon ani icon-1" swiper-animate-effect="zoomIn"
								swiper-animate-duration="1s" swiper-animate-delay="0.5s"></div>
							<div class="section-icon ani icon-2" swiper-animate-effect="zoomIn"
								swiper-animate-duration="1s" swiper-animate-delay="0.5s"></div>
							<div class="section-icon ani icon-3" swiper-animate-effect="zoomIn"
								swiper-animate-duration="1s" swiper-animate-delay="0.5s"></div>
						</div>
						<div class="section-right">
							<h2 class="right-title ani" swiper-animate-effect="fadeInUp" swiper-animate-duration="1s"
								swiper-animate-delay="0s">首页精选</h2>
							<p class="right-sub ani" swiper-animate-effect="fadeInUp" swiper-animate-duration="1s"
								swiper-animate-delay="0.5s">省心省力 快速锁定心仪案例</p>
							<p class="right-desc ani" swiper-animate-effect="fadeInUp" swiper-animate-duration="1s"
								swiper-animate-delay="1s">AI智能推荐，精准捕捉您的营销诉求，提升客户转化</p>
						</div>
					</div>
				</div>
				<!-- 会员 section-member -->
				<div class="swiper-slide section section-member">
					<div class="section-content clearfix">
						<div class="section-left">
							<div class="section-phone-content ani" swiper-animate-effect="zoomIn"
								swiper-animate-duration="1s" swiper-animate-delay="0s"></div>
							<div class="section-phone ani" swiper-animate-effect="zoomIn" swiper-animate-duration="1s"
								swiper-animate-delay="0s"></div>
							<div class="section-icon ani icon-1" swiper-animate-effect="zoomIn"
								swiper-animate-duration="1s" swiper-animate-delay="0.5s"></div>
							<div class="section-icon ani icon-2" swiper-animate-effect="zoomIn"
								swiper-animate-duration="1s" swiper-animate-delay="0.5s"></div>
							<div class="section-icon ani icon-3" swiper-animate-effect="zoomIn"
								swiper-animate-duration="1s" swiper-animate-delay="0.5s"></div>
						</div>
						<div class="section-right">
							<h2 class="right-title ani" swiper-animate-effect="fadeInUp" swiper-animate-duration="1s"
								swiper-animate-delay="0s">会员专属</h2>
							<p class="right-sub ani" swiper-animate-effect="fadeInUp" swiper-animate-duration="1s"
								swiper-animate-delay="0.5s">专业设计团队 倾心打造 <br /> 专属优质案例 每日更新</p>
							<p class="right-desc ani" swiper-animate-effect="fadeInUp" swiper-animate-duration="1s"
								swiper-animate-delay="1s">高品质、高要求、高质量，<br /> 追求卓越的你，必定会与成功不期而遇。</p>
						</div>
					</div>
				</div>
				<!-- 营销 section-marketing -->
				<div class="swiper-slide section section-marketing">
					<div class="section-content clearfix">
						<div class="section-left">
							<div class="section-phone ani" swiper-animate-effect="zoomIn" swiper-animate-duration="1s"
								swiper-animate-delay="0s"></div>
							<div class="section-icon ani icon-1" swiper-animate-effect="zoomIn"
								swiper-animate-duration="1s" swiper-animate-delay="0.5s"></div>
							<div class="section-icon ani icon-2" swiper-animate-effect="zoomIn"
								swiper-animate-duration="1s" swiper-animate-delay="0.5s"></div>
							<div class="section-icon ani icon-3" swiper-animate-effect="zoomIn"
								swiper-animate-duration="1s" swiper-animate-delay="0.5s"></div>
						</div>
						<div class="section-right">
							<h2 class="right-title ani" swiper-animate-effect="fadeInUp" swiper-animate-duration="1s"
								swiper-animate-delay="0s">营销话术</h2>
							<p class="right-sub ani" swiper-animate-effect="fadeInUp" swiper-animate-duration="1s"
								swiper-animate-delay="0.5s">一键解锁海量营销话术 <br /> 24h 智能问答，助您与客户畅聊无阻</p>
							<p class="right-desc ani" swiper-animate-effect="fadeInUp" swiper-animate-duration="1s"
								swiper-animate-delay="1s">客户问题不知怎么回答？<br /> 快来问问我吧！</p>
						</div>
					</div>
				</div>
				<!-- 发现 section-discover -->
				<div class="swiper-slide section section-discover">
					<div class="section-content clearfix">
						<div class="section-left">
							<div class="section-phone-content ani" swiper-animate-effect="zoomIn"
								swiper-animate-duration="1s" swiper-animate-delay="0s"></div>
							<div class="section-phone ani" swiper-animate-effect="zoomIn" swiper-animate-duration="1s"
								swiper-animate-delay="0s"></div>
							<div class="section-icon ani icon-1" swiper-animate-effect="zoomIn"
								swiper-animate-duration="1s" swiper-animate-delay="0.5s"></div>
							<div class="section-icon ani icon-2" swiper-animate-effect="zoomIn"
								swiper-animate-duration="1s" swiper-animate-delay="0.5s"></div>
							<div class="section-icon ani icon-3" swiper-animate-effect="zoomIn"
								swiper-animate-duration="1s" swiper-animate-delay="0.5s"></div>
						</div>
						<div class="section-right">
							<h2 class="right-title ani" swiper-animate-effect="fadeInUp" swiper-animate-duration="1s"
								swiper-animate-delay="0s">发现广场</h2>
							<p class="right-sub ani" swiper-animate-effect="fadeInUp" swiper-animate-duration="1s"
								swiper-animate-delay="0.5s">万名医美达人资源共享 <br /> 海量案例素材任您挑选 </p>
							<p class="right-desc ani" swiper-animate-effect="fadeInUp" swiper-animate-duration="1s"
								swiper-animate-delay="1s">独乐乐不如众乐乐，何况还能赚钱？</p>
						</div>
					</div>
				</div>
				<!-- 会员介绍 section-introduction -->
				<div class="swiper-slide section section-introduction">
					<div class="introduction-content clearfix">
						<div class="introduction-title ani" swiper-animate-effect="zoomIn" swiper-animate-duration="1s"
							swiper-animate-delay="0s">
							<img src="../assets/images/section-introduction-title.png">
						</div>
						<div class="introduction-item">
							<div class="item ani" swiper-animate-effect="fadeInDown" swiper-animate-duration="1s"
								swiper-animate-delay="0.5s">
								<img class="introduction-icon" src="../assets/images/introduction-icon1.png">
								<div class="introduction-price">
									<div class="title">个人版VIP</div>
									<div class="price" style="color: #31C27C">¥<span>3998</span>/年</div>
								</div>
								<div class="introduction-desc">专业案例每日推送<br />多项功能免费使用</div>
							</div>
							<div class="item ani" swiper-animate-effect="fadeInUp" swiper-animate-duration="1s"
								swiper-animate-delay="0.5s">
								<img class="introduction-icon" src="../assets/images/introduction-icon2.png">
								<div class="introduction-price">
									<div class="title">团队版VIP</div>
									<div class="price" style="color: #FE9C11">¥<span>18888</span>/年</div>
								</div>
								<div class="introduction-desc">
									设计师案例加倍推送<br />全部功能免费使用<br />子账号无限开通，品牌水印定制化<br />悉心客服贴心服务</div>
							</div>
							<div class="item ani" swiper-animate-effect="fadeInDown" swiper-animate-duration="1s"
								swiper-animate-delay="0.5s">
								<img class="introduction-icon" src="../assets/images/introduction-icon3.png">
								<div class="introduction-price">
									<div class="title">定制版VIP</div>
									<div class="price" style="color: #FF4800 ">¥<span>39998</span>/年</div>
								</div>
								<div class="introduction-desc">
									高阶企业定制专属<br />资深设计师对接，打造品牌专属案例<br />客服环绕式服务，全心为你想你所想<br />软件使用不设限，独享标识畅游美业</div>
							</div>
						</div>
					</div>
				</div>
				<!-- 联系 section-contact -->
				<div class="swiper-slide section section-contact">
					<div class="section-content clearfix">
						<div class="content-left">
							<h2 class="content-title ani" swiper-animate-effect="fadeInUp" swiper-animate-duration="1s"
								swiper-animate-delay="0s">联系我们</h2>
							<p class="content-sub ani" swiper-animate-effect="fadeInUp" swiper-animate-duration="1s"
								swiper-animate-delay="0.5s">安徽一美科技有限公司 </p>
							<p class="content-desc ani" swiper-animate-effect="fadeInUp" swiper-animate-duration="1s"
								swiper-animate-delay="1s">公司地址：合肥市四里河路万科中心2007</p>
							<p class="content-desc ani" swiper-animate-effect="fadeInUp" swiper-animate-duration="1s"
								swiper-animate-delay="1.2s">邮编：230001</p>
							<p class="content-desc ani" swiper-animate-effect="fadeInUp" swiper-animate-duration="1s"
								swiper-animate-delay="1.4s">电话：0551-6788301</p>
							<p class="content-desc ani" swiper-animate-effect="fadeInUp" swiper-animate-duration="1s"
								swiper-animate-delay="1.6s">商务合作：app1mei@163.com</p>
							<p class="content-desc ani" swiper-animate-effect="fadeInUp" swiper-animate-duration="1s"
								swiper-animate-delay="1.8s">侵权投诉：ah1mei@163.com </p>
						</div>
						<div class="content-right">
							<img class="ani" swiper-animate-effect="zoomIn" swiper-animate-duration="1s"
								swiper-animate-delay="0s" src="../assets/images/address-bg.png">
							<img class="address-location ani" swiper-animate-effect="zoomIn"
								swiper-animate-duration="1s" swiper-animate-delay="1s"
								src="../assets/images/address-location.gif">
						</div>
					</div>
					<div class="content-filing">
						<p class="content-title">安徽一美科技有限公司</p>
						<p>皖网文（2019）6047-305号 <span @click="handleICP">皖ICP备15026816号</span></p>
					</div>
				</div>
			</div>
			<div class="icon-kefu">
				<a href="javascript:void(0)" onclick="openJesongChatByGroup(33097,40372);return false;"></a>
			</div>
			<div class="list-bg" :class="waveFlag?'list-bg-show':''">
				<svg class="waves" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
					viewBox="0 24 150 28" preserveAspectRatio="none">
					<defs>
						<path id="gentle-wave" class="st0" d="M-160 35.6c30 0
												58-6.6 88-6.6s
												58 6.6 88 6.6
												58-6.6 88-6.6
												58 6.6 88 6.6
												v59h-352V35.6z">
						</path>
						<linearGradient id="primary" x1="0%" y1="0%" x2="0%" y2="50%">
							<stop offset="0%" style="stop-color:#36E9E8; stop-opacity:1" />
							<stop offset="100%" style="stop-color:#25D4D0; stop-opacity:1" />
						</linearGradient>
					</defs>
					<g class="parallax">
						<use style="fill:url(#primary)" xlink:href="#gentle-wave" x="48" y="0"></use>
						<use style="fill:url(#primary)" xlink:href="#gentle-wave" x="48" y="0"></use>
					</g>
				</svg>
			</div>
			<div class="swiper-pagination"></div>
		</div>
	</div>
</template>

<script>
	import Swiper from 'swiper'
	import * as swiperAni from '../assets/js/swiper.animate1.0.3.min.js'
	export default {
		name: 'Home',
		components: {},
		props: {},
		data() {
			return {
				navFlag: false,
				waveFlag: false
			}
		},
		computed: {},
		watch: {},
		created() {},
		mounted() {
			const that = this
			this.swiper = new Swiper('.swiper-container', {
				direction: 'vertical',
				mousewheel: true,
				pagination: {
					el: '.swiper-pagination',
					clickable: true
				},
				on: {
					init() {
						// 隐藏动画元素 
						swiperAni.swiperAnimateCache(this)
						// 初始化完成开始动画
						swiperAni.swiperAnimate(this)
					},
					slideChangeTransitionStart() {
						// 每个slide开始切换时也运行当前slide动画
						swiperAni.swiperAnimate(this)
					},
					slideChange() {
						if (this.activeIndex === 0) {
							that.navFlag = false
						} else {
							that.navFlag = true
						}
						if (this.activeIndex === 0 || this.activeIndex === 1 || this.activeIndex === 6 || this
							.activeIndex === 7) {
							that.waveFlag = false
						} else {
							that.waveFlag = true
						}
					}
				}
			})
			// 判断是手机还是pc
			if (/Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent)) {
				window.location.href = 'http://www.11mei.cn/m'
			} else {

			}
		},
		methods: {
			handleICP() {
				window.open('https://beian.miit.gov.cn/', '_blank')
			}
		}
	}
</script>

<style lang="less" scoped>
	@import './home.less';

	.wrapper,
	.swiper-container,
	.swiper-slide,
	.section {
		width: 100%;
		height: 100%;
	}

	// .section {
	// 	z-index: 1;
	// }

	.swiper-container-vertical>.swiper-pagination-bullets {
		right: 88px;
		top: 50%;
		transform: translate3d(0px, -50%, 0);
	}

	/deep/.swiper-pagination-bullet-active {
		height: 24px;
		border-radius: 4px;
		background: #0af1eb;
	}

	@media screen and (max-width: 1440px) {
		.swiper-container-vertical>.swiper-pagination-bullets {
			right: 58px;
		}
	}

	// list-bg
	.list-bg {
		width: 100%;
		position: absolute;
		right: 0;
		bottom: 0;
		background: #FFFFFF;
		display: none;
	}

	.list-bg-show {
		display: block;
	}

	.list-bg .bg-waves,
	.list-bg .waves {
		width: 100%;
		height: 300px;
		margin-bottom: -5px;
		position: absolute;
		left: 0;
		bottom: 0;
	}

	.list-bg .parallax>use {
		-webkit-animation: move-forever 14s linear infinite;
		animation: move-forever 14s linear infinite
	}

	.list-bg .parallax>use:nth-child(1) {
		-webkit-animation-delay: -2s;
		animation-delay: -2s;
		-webkit-animation-duration: 7s;
		animation-duration: 7s;
		fill-opacity: .2
	}

	.list-bg .parallax>use:nth-child(2) {
		-webkit-animation-delay: -3s;
		animation-delay: -3s;
		-webkit-animation-duration: 12s;
		animation-duration: 12s;
		fill-opacity: 1
	}

	@-webkit-keyframes move-forever {
		0% {
			-webkit-transform: translate3d(-90px, 0, 0);
			transform: translate3d(-90px, 0, 0)
		}

		100% {
			-webkit-transform: translate3d(85px, 0, 0);
			transform: translate3d(85px, 0, 0)
		}
	}

	@keyframes move-forever {
		0% {
			-webkit-transform: translate3d(-90px, 0, 0);
			transform: translate3d(-90px, 0, 0)
		}

		100% {
			-webkit-transform: translate3d(85px, 0, 0);
			transform: translate3d(85px, 0, 0)
		}
	}

	.icon-kefu {
		a {
			width: 142px;
			height: 172px;
			z-index: 200;
			position: absolute;
			top: 25%;
			right: 10%;

			&::after {
				content: "";
				width: 100%;
				height: 100%;
				background: url(../assets/images/icon-kefu.png) no-repeat;
				position: absolute;
			}
		}
	}
</style>
